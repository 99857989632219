<template>
  <div>
    <member-menu/>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form v-if="choosePackageOrService" @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col sm="12">
            <iq-card body-class=" profile-page p-0">
              <template v-slot:body>
                <div class="profile-header">
                  <div class="profile-info p-2">
                    <b-row>
                      <b-col md="6" sm="12">
                        <div class="user-detail pl-5">
                          <div class="d-flex flex-wrap align-items-center">
                            <div class="profile-img pr-4">
                              <span>
                              <b-img v-bind:src="user.profile" @click="openFile" ref="file" alt="profile-img" fluid class="avatar-80 cursor-pointer" />
                              </span>
                              <input type="file" id="file"  @change="previewImage" class="d-none position-absolute" accept="image/*" style="opacity: 0;" />
                            </div>
                            <div class="profile-detail d-flex align-items-center">
                              <h4 class="text-capitalize">{{user.name}}</h4>
                              <h5 class="m-0 pl-3 text-secondary"> {{user.phone}} </h5>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="6" sm="12" class="d-flex align-items-center justify-content-end">
                        <img :src="qrCodeImage"  alt="qr-img" fluid class="cursor-pointer avatar-60 mr-5">
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="8">
            <iq-card>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-6"
                                  label="Select Packages:"
                                  label-for="project"
                    >
                      <div>
                        <v-select :options="allPackages" multiple v-model="memberShipPackages" label="title"
                                  :selectable="option => !memberShipPackages.includes(option.id)"
                                  :reduce="service => service.id" @option:deselected="removePackage" @option:selecting="addPackages">
                        </v-select>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-6" label="Payment Type:" label-for="period">
                      <div class="periodNumbers">

                        <span :class="[{ 'shadow selectedNumber' : isSelected('cash')}]" @click="memberShip.payment_method = cash">
                          Cash
                        </span>
                        <span :class="[{ 'shadow selectedNumber' : isSelected('visa')}]" @click="memberShip.payment_method = visa">
                          Visa
                        </span>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-12" label="Paid Amount:" label-for="amount" v-if="memberShipPackages.length > 0">
                      <div class="projectsPrice">
                        <div class="content-price" v-for="(memberShipData, key) in selectedPackages" :key="key">
                          <p class="d-flex justify-content-around"><span>{{ memberShipData.title }}</span> - <span class="text-primary">{{memberShipData.project[0].pivot.price - (parseFloat((memberShipData.project[0].pivot.price * memberShip.discount) )/ 100)}} L.E</span></p>
                          <ValidationProvider :name="memberShipData.title+' Price'" :rules="'required|max_value:'+ (memberShipData.project[0].pivot.price - (memberShipData.project[0].pivot.price * memberShip.discount)/ 100) " v-slot="{ errors }">
                            <b-form-input v-model="memberShipData.price" type="number" placeholder="Ex: 100" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-6" label="Discount:" label-for="discount">
                      <div class="periodNumbers">

                        <span :class="[{ 'shadow selectedNumber' : isSelectedDiscount(10)}]" @click="memberShip.discount = 10">
                          10
                        </span>
                        <span :class="[{ 'shadow selectedNumber' : isSelectedDiscount(20)}]" @click="memberShip.discount = 20">
                          20
                        </span>
                        <span :class="[{ 'shadow selectedNumber' : isSelectedDiscount(30)}]" @click="memberShip.discount = 30">
                          30
                        </span>
                        <span :class="[{ 'shadow selectedNumber' : isSelectedDiscount(50)}]" @click="memberShip.discount = 50">
                          50
                        </span>
                        <span :class="[{ 'shadow selectedNumber' : isSelectedDiscount(100)}]" @click="memberShip.discount = 100">
                          100
                        </span>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-6" label="Currency:" label-for="currency">
                      <div class="periodNumbers">

                        <span :class="[{ 'shadow selectedNumber' : isSelectedCurrency('EGP')}]" @click="memberShip.currency = 'EGP'">
                          EGP
                        </span>
                        <span :class="[{ 'shadow selectedNumber' : isSelectedCurrency('USD')}]" @click="memberShip.currency = 'USD'">
                          USD
                        </span>
                        <span :class="[{ 'shadow selectedNumber' : isSelectedCurrency('AED')}]" @click="memberShip.currency = 'AED'">
                          AED
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group class="col-md-6" label="Get Services:" >
                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" >
                        <div class="custom-switch-inner">
                          <input type="checkbox" class="custom-control-input bg-primary" v-model="ifService" id="service">
                          <label class="custom-control-label" for="service" data-on-label="on" data-off-label="off">
                          </label>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-6" label="Medical Case:" >
                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" >
                        <div class="custom-switch-inner">
                          <input type="checkbox" class="custom-control-input bg-primary" v-model="ifMedical" id="medical">
                          <label class="custom-control-label" for="medical" data-on-label="on" data-off-label="off">
                          </label>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group v-if="ifMedical" class="col-md-12" label="Note:" >
                      <ValidationProvider name="note" ref="note" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="memberShip.medical" type="text" placeholder="note" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>
                  <b-row v-if="ifService == true">
                    <b-form-group class="col-md-12"
                                  label="Select Services:"
                                  label-for="Services"
                    >
                      <div>
                        <v-select :options="allServices"  multiple
                                  label="title"
                                  v-model="memberShipServices"
                                  :selectable="services => !memberShipServices.includes(services.id)"
                                  :reduce="services => services.id"
                                  @option:deselected="removeService" @option:selecting="addServices"
                        >
                        </v-select>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-12" label="Paid Amount:" label-for="amount" v-if="memberShipServices.length > 0">
                      <div class="projectsPrice">
                        <div class="content-price" v-for="(memberShipData, key) in selectedServices" :key="key">
                          <p class="d-flex justify-content-around"><span>{{ memberShipData.title }}</span> - <span class="text-primary">{{memberShipData.project[0].pivot.price - (parseFloat((memberShipData.project[0].pivot.price * memberShip.discount) )/ 100)}} L.E</span></p>
                          <ValidationProvider :name="memberShipData.title+' Price'" :rules="'required|max_value:'+(memberShipData.project[0].pivot.price - (memberShipData.project[0].pivot.price * memberShip.discount)/ 100)" v-slot="{ errors }">
                            <b-form-input v-model="memberShipData.price"  type="number" @input="calculatePaidAmountInServices" placeholder="Ex: 100" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="4">
            <div class="iq-card">
              <div class="iq-card-body">
                <h4 class="mb-2">Package Details</h4>
                <span>
                  <div class="d-flex justify-content-between">
                    <span>Package Cost</span>
                    <span><strong>{{ pricing.packageCost || 0 }} L.E</strong></span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Paid Amount</span>
                    <span><strong>{{ calculatePaidAmountInPackages() || 0 }} L.E</strong></span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Remaining Amount:</span>
                    <span><strong>{{ calculateTotalPackages -  calculatePaidAmountInPackages() || 0 }} L.E</strong></span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>total:</span>
                    <span><strong>{{ calculateTotalPackages || 0 }} L.E</strong></span>
                  </div>
                </span>
                <hr v-if="ifService">
                <span v-if="ifService">
                  <h4 class="mb-2">Services Details</h4>
                <span>
                  <div class="d-flex justify-content-between">
                    <span>Paid Amount</span>
                    <span><strong>{{ calculatePaidAmountInServices() || 0 }} L.E</strong></span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Remaining Amount:</span>
                    <span><strong>{{ (pricing.totalServiceCost - ((pricing.totalServiceCost * memberShip.discount)/ 100))- calculatePaidAmountInServices() || 0 }} L.E</strong></span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>total</span>
                    <span><strong>{{(pricing.totalServiceCost - (pricing.totalServiceCost * memberShip.discount)/ 100) }} L.E</strong></span>
                  </div>
                </span>
                </span>
                <hr>
                <h4 class="mb-2">Total</h4>
                <div class="d-flex justify-content-between">
                  <span>Discount</span>
                  <span class="text-success"><strong>{{memberShip.discount || 0 }}%</strong></span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="text-primary">Grand Total</span>
                  <span><strong>{{ calculateTotal || 0}} L.E</strong></span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="text-success">Total Paid</span>
                  <span><strong>{{ Number(calculatePaidAmountInServices() + calculatePaidAmountInPackages()) || 0}} L.E</strong></span>
                </div>
                <div class="d-flex justify-content-between">
                  <span  class="text-danger">Total Remaining</span>
                  <span><strong>{{ Number(( (pricing.totalServiceCost - (pricing.totalServiceCost * memberShip.discount)/ 100) - calculatePaidAmountInServices() )) + (calculateTotalPackages - calculatePaidAmountInPackages()) }} L.E</strong></span>
                </div>
                <div class="mt-4">
                  <button type="submit" class="btn w-100 btn-primary" @click.prevent="cretaeMembership">Confirm Membership</button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </form>
      <form v-else @submit.prevent="handleSubmit(saveUser)"  >
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:body>
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="Phone Number:" label-for="phone_number:">
                    <ValidationProvider name="phone_number" ref="phone_number" rules="required|digits:11" v-slot="{ errors }">
                      <b-form-input :disabled="disabled == 1" @update="searchPhoneNumber"  v-model="userData.phone" type="number" placeholder="Phone Number" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <div v-if="loadingDataFromPhone" class="text-center col-md-12">
                    <b-spinner small type="grow"></b-spinner>
                    Loading...
                  </div>
                </b-row>
                <b-row  v-if="finishSearch">
                  <b-form-group class="col-md-12" label="Full Name:" label-for="full_name:">
                    <ValidationProvider name="full_name" ref="name" rules="required" v-slot="{ errors }">
                      <b-form-input v-model="userData.name" type="text" placeholder="Full Name" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="National ID:" label-for="National ID:">
                    <ValidationProvider name="National ID" ref="national" rules="required|digits:14" v-slot="{ errors }">
                      <b-form-input v-model="userData.national" type="number" placeholder="National ID" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Birthday::" label-for="birthday::">
                    <ValidationProvider name="birthday:" ref="birthday" v-slot="{ errors }">
                      <b-form-input type="date" v-model="userData.birthday" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" label="Email:" label-for="Email:">
                    <ValidationProvider name="Email:" ref="email" rules="required|email" v-slot="{ errors }">
                      <b-form-input v-model="userData.email" type="text" placeholder="Email" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <div class="col-md-12 d-flex justify-content-end">
                        <span class="mr-2">
                          <b-button v-if='loadingSaveAsGuest'  variant="primary" disabled>
                            <b-spinner small type="grow"></b-spinner>
                            loading...
                          </b-button>
                        <b-button variant="primary" type="submit" @click="userData.type = 'guest'">Save as Guest</b-button>
                        </span>
                    <span class="mr-2">
                          <b-button v-if='loadingGetMemberShip'  variant="primary" disabled>
                            <b-spinner small type="grow"></b-spinner>
                            loading...
                          </b-button>
                          <b-button variant="primary" type="submit" @click="userData.type = 'member'">Get Membership / Service</b-button>
                        </span>
<!--                    <span>
                          <b-button v-if='loadingGetService'  variant="primary" disabled>
                            <b-spinner small type="grow"></b-spinner>
                            loading...
                          </b-button>
                        <b-button variant="primary" type="submit" @click="userData.type = 'member'">Get Service</b-button>
                        </span>-->
                  </div>
                </b-row>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'

export default {
  name: 'newMember',
  data () {
    return {
      choosePackageOrService: false,
      loadingSaveAsGuest: false,
      loadingGetMemberShip: false,
      loadingGetService: false,
      finishSearch: false,
      qrCodeImage: 'https://api.qrserver.com/v1/create-qr-code/?data=hello_peter',
      ifService: false,
      ifMedical: false,
      loadingDataFromPhone: false,
      user: {
        profile: require('../../assets/images/user/user-11.png'),
        name: '',
        phone: '',
        id: '',
        membership_id: '',
        national_id: ''
      },
      image: '',
      disabled: 0,
      userData: {
        phone: '',
        name: '',
        type: '',
        email: '',
        national: '',
        birthday: ''
      },
      memberShipPackages: [],
      selectedPackages: [],
      memberShipServices: [],
      selectedServices: [],
      memberShip: {
        payment_method: 'cash',
        discount: 0,
        currency: 'EGP'
      },
      pricing: {
        packageCost: 0,
        PackageRemainingAmount: 0,
        totalPackageCost: 0,
        totalServiceCost: 0,
        ServicePaidAmount: 0,
        ServiceRemainingAmount: 0,
        total: 0
      },
      allPackages: [],
      allServices: []
    }
  },
  methods: {
    isSelected (i) {
      // eslint-disable-next-line eqeqeq
      return i == this.memberShip.payment_method
    },
    isSelectedDiscount (i) {
      // eslint-disable-next-line eqeqeq
      return i == this.memberShip.discount
    },
    isSelectedCurrency (i) {
      // eslint-disable-next-line eqeqeq
      return i == this.memberShip.currency
    },
    openFile () {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById('file').click()
    },
    previewImage: function (e) {
      console.log(e.target.files)
      debugger
      var files = e.target.files || e.dataTransfer.files
      console.log('files', files[0])
      if (!files.length) return
      this.createImage(files[0])
      const fd = new FormData()
      fd.append('image', files[0], files[0].name)
      this.$store.dispatch('uploadMemberImage', { userId: this.user.id, image: fd }).then(res => {
        core.showSnackbar('success', res.data.message)
      })
    },
    createImage (file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        this.user.profile = e.target.result
      }
      reader.readAsDataURL(file)
    },
    addPackages (packageData) {
      packageData.price = 0
      this.pricing.packageCost += packageData.project[0].pivot.price
      this.selectedPackages.push(packageData)
    },
    removePackage (packageData) {
      this.pricing.packageCost -= packageData.project[0].pivot.price
      var position = this.selectedPackages.findIndex(packages => packages.id === packageData.id)
      this.selectedPackages.splice(position, 1)
    },
    addServices (serviceData) {
      serviceData.price = 0
      this.pricing.totalServiceCost += serviceData.project[0].pivot.price
      this.selectedServices.push(serviceData)
    },
    removeService (serviceData) {
      this.pricing.totalServiceCost -= serviceData.project[0].pivot.price
      var position = this.selectedServices.findIndex(service => service.id === serviceData.id)
      this.selectedServices.splice(position, 1)
    },
    // form methods
    searchPhoneNumber () {
      this.finishSearch = false
      if (this.userData.phone.length === 11) {
        this.loadingDataFromPhone = true
        this.disabled = 1
        this.$store.dispatch('serachByPhone', this.userData.phone).then(res => {
          this.loadingDataFromPhone = false
          this.disabled = 0
          console.log(res)
          if (!res.data.data) {
            this.userData = {
              phone: this.userData.phone,
              name: '',
              type: '',
              email: '',
              national: '',
              birthday: ''
            }
          } else {
            this.userData = {
              phone: res.data.data.phone,
              name: res.data.data.name,
              email: res.data.data.member ? res.data.data.member.email : '',
              national: res.data.data.member ? res.data.data.member.national_id : '',
              birthday: res.data.data.member ? res.data.data.member.birthday : ''
            }
          }
          this.finishSearch = true
        })
      }
    },
    saveUser () {
      this.$store.dispatch('storeUser', this.userData).then(res => {
        this.loadingSaveAsGuest = false
        this.loadingGetMemberShip = false
        this.loadingGetService = false
        console.log('res.data', res.data.data)
        this.user.name = res.data.data.name
        this.user.phone = res.data.data.phone
        this.user.id = res.data.data.id
        this.user.membership_id = res.data.data.membership_id
        this.user.national_id = res.data.data.national_id
        // this.user.profile = res.data.data.profile
        // eslint-disable-next-line eqeqeq
        if (res.data.data.type == 'member') {
          this.changeForm()
        }
        core.showSnackbar('success', res.data.message)
      }).catch(err => {
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          this.$refs[key].setErrors(value)
        }
      })
    },
    cretaeMembership () {
      this.$store.dispatch('cretaeMembership', {
        userId: this.user.id,
        data: {
          packages: [...this.selectedPackages, ...this.selectedServices],
          ...this.memberShip,
          medical_case: this.ifMedical ? 1 : 0
        }
      }).then(res => {
        core.showSnackbar('success', res.data.message)
      }).catch(err => {
        console.log(err.response.data)
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', `${key}: ${value}`)
        }
      })
    },
    changeForm () {
      // eslint-disable-next-line eqeqeq
      if (this.userData.type == 'member') {
        this.choosePackageOrService = true
      }
    },
    calculatePaidAmountInPackages () {
      return this.selectedPackages.reduce((sum, { price }) => Number(sum) + Number(price), 0)
    },
    calculatePaidAmountInServices () {
      return this.selectedServices.reduce((sum, { price }) => Number(sum) + Number(price), 0)
    }
  },
  computed: {
    calculateTotalPackages () {
      return this.pricing.packageCost - ((this.pricing.packageCost * this.memberShip.discount) / 100)
    },
    totalRemaining () {
      return ((this.pricing.totalServiceCost) - this.memberShip.service_amount) + this.calculatePackageRemainingAmount
    },
    calculateTotalServices () {
      return this.pricing.totalServiceCost - ((this.pricing.totalServiceCost * this.memberShip.discount) / 100)
    },
    calculateTotal () {
      return this.calculateTotalPackages + this.calculateTotalServices
    }
  },
  watch: {
    ifService (value) {
      if (!value) {
        this.selectedServices = []
        this.memberShipServices = []
        this.pricing.totalServiceCost = ''
      }
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.$store.dispatch('getCreateMemberShip').then(res => {
      this.allPackages = res.data.data.packages
      this.allServices = res.data.data.services
    })
  }
}
</script>
<style scoped>
.profile-img{
  margin-top: 0 !important;
}
.periodNumbers , .projectsPrice{
  padding: 5px;
  background: transparent;
  border: 1px solid #d7dbda;
  font-size: 14px;
  color: var(--iq-secondary);
  border-radius: 10px;
  background: #92e8cb00;
  display: flex;
  align-items: center;
  justify-content: start;
}
.projectsPrice{
  background: var(--iq-bg-light-color);
}
.projectsPrice .content-price{
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}
.content-price p {
  margin: 1px 0;
  text-align: center;
  /* color: #202020; */
  color: var(--iq-dark);
}
.periodNumbers span {
  cursor: pointer;
  background: #ececec;
  padding: 5px 15px;
  border-radius: 10px;
  margin-right: 10px;
  color: #5b5b5b;
}
.selectedNumber{
  color: var(--iq-white) !important;
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
}
</style>
